<template>
  <div>
    <taskToday />
  </div>
</template>

<script>
export default {
  components: {
    taskToday: () => import("./w/tasksToday.vue")
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
